a {
    text-decoration: none;
    color: #000000;
}

.hover-table .hover-row:hover {
    background-color: #DDDDDD;
}

.ql-editor{
    min-height: 250px;
    overflow: auto;
}
